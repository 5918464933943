import { render, staticRenderFns } from "./top-color.vue?vue&type=template&id=671d638d&"
import script from "./top-color.vue?vue&type=script&lang=js&"
export * from "./top-color.vue?vue&type=script&lang=js&"
import style0 from "./top-color.vue?vue&type=style&index=0&id=671d638d&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/sharedfile/jenkins/workspace/qas-video-platform-ui-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('671d638d')) {
      api.createRecord('671d638d', component.options)
    } else {
      api.reload('671d638d', component.options)
    }
    module.hot.accept("./top-color.vue?vue&type=template&id=671d638d&", function () {
      api.rerender('671d638d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/page/index/top/top-color.vue"
export default component.exports