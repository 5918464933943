<template>
  <div class="chat-wrap">
    <!-- <div class="kefu" @click="showDialogue">
      <a href="javascript:;">
        <i class="el-icon-service"></i>
      </a>
      <div class="red-msg" v-if="isread"></div>
    </div>     -->
    <el-badge
      :value="totalUnReadMessage > 0 ? totalUnReadMessage : '' "
      :max="99">
      <i class="el-icon-service" @click="showDialogue"/>
    </el-badge>
    <el-dialog class="chat-dialog" :visible.sync="dialogShow" width="50%" :close-on-press-escape="false" center :before-close="beforeCloseChat">
        <div class="user-list" v-if="chatList.length > 0">
          <a href="javascript:;" v-for="(chat, index) in chatList" :key="index" :class="currentChat && currentChat.id === chat.id ? 'active' : ''" @click="showChat(chat)">
            <div class="message-user">
              <div class="muser-img">
                <img :src="chat.avatar" v-if="chat.avatar && chat.avatar != ''" alt="头像" />
              </div>
              <div class="muser-name">
                  <div class="muser-info-wrap">
                    <div class="user-name gray">
                      {{chat.name}}
                      <!-- <span class="axis">{{item.axis}}</span> -->
                    </div>
                    <span class="user-content"> {{ chat.sign }} </span>
                  </div>
                  <div class="muser-cancelbtn-wrap">

                  </div>
              </div>
              <div class="unread" v-if="chat.unReadCount > 0">{{chat.unReadCount}}</div>
            </div>
          </a>
        </div>
        <div class="chat-dialog-rightwrap">
          <div class="muser-info">
            <div class="user-head-image" v-if="currentChat.img && currentChat.img != ''">
                <img :src="currentChat.img">
            </div>
            <div class="user-head-name">
              {{currentChat.name}}
            </div>
          </div>
          <div class="message-list" id="message-list" @scroll="showScroll($event)">
            <div class="list-info" v-for="item in messageList" :key="item.id">

              <div class="send-time">
                {{item.date}}
              </div>

              <div class="chat-log" v-if="!item.mine">
                <div class="chat-userimg">
                  <img :src="item.img">
                </div>
                <div class="chat-content">
                  <div class="content-username">{{item.name}}</div>
                  <div class="left-content-text">
                    <span v-if="item.text" v-html="item.text.text"></span>
                    <div class="msg-goods" v-if="item.product">
                      <img v-if="item.product.prodImage && item.product.prodImage!= ''" :src="item.product.prodImage" />
                      <p>{{item.product.prodName}}</p>
                      <span class="msg-price">
                        ￥{{item.product.prodPrice}}
                      </span>
                    </div>
                    <div class="msg-order" @click.stop="checkOrder(item.order)" v-if="item.order">
                        <div class="order-image">
                          <img :src="item.order.prodImage" v-if="item.order.prodImage && item.order.prodImage != ''">
                        </div>
                        <div class="order-info">
                            <div class="nameAndSku">
                                <div class="order-prodName">
                                  {{item.order.prodName}}
                                </div>
                                <div class="order-prodSku">
                                  <span>{{item.order.skuStr}}</span>
                                </div>
                            </div>
                            <div class="priceAndNum">
                                <div class="order-price">
                                  ￥{{item.order.prodPrice}}
                                </div>
                                <div class="order-num">
                                  x{{item.order.prodNum}}
                                </div>
                                <div class="order-status">
                                  {{ item.order.orderStatus | orderStatusFilter }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <img :src="item.product.prodImage" v-if="item.product && item.product.prodImage && !item.product.productId" style="width: 200px;" />
                  </div>
                </div>
              </div>

              <div class="chat-log-right" v-else>
                <div class="chat-content">
                  <div class="content-username">{{item.name}}</div>
                  <div class="left-content-text">
                    <span v-if="item.text" v-html="item.text.text"></span>
                    <!-- <img :src="item.imageUrl" v-if="item.imageUrl && !item.productId" style="width: 200px;" /> -->
                  </div>
                </div>
                <div class="chat-userimg">
                  <img :src="item.img">
                </div>
              </div>
            </div>
          </div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    ChatListUtils,
    imageLoad,
    MessageTargetType
  } from "@/util/chatUtils";
  import RequestUtils from "@/util/RequestUtils";
  import conf from "@/chatConfig/index";
  export default {
    filters:{
      orderStatusFilter(status) {
        const statusMap = {
          0: '待付款',
          1: '待发货',
          2: '待收货',
          3: '已收货',
          4: '已关闭',
          5: '已完成',
          6: '售后中',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        config: {},
        tempKeylist: [
          '您好，欢迎光临***品牌旗舰店，很高兴为您服务！/:^_^',
          '您好，/:^_^不好意思让您久等了，您看中这款是吗（截图发给客户看），要什么颜色的呢？',
          '亲，这款是打特价的价格呢，已经是最低价了哦，现在全场三件（根据具体情况而定）包邮哦，您挑多一件了',
          '您好，欢迎光临***品牌旗舰店，很高兴为您服务！/:^_^',
          '您好，/:^_^不好意思让您久等了，您看中这款是吗（截图发给客户看），要什么颜色的呢？',
          '亲，这款是打特价的价格呢，已经是最低价了哦，现在全场三件（根据具体情况而定）包邮哦，您挑多一件了'
        ],
        keylist: [],
        msg: '',
        list: [],
        dialogShow: false,
        tempObject:{
          name:'大毛',
          sign: 'hellow world',
          avatar: 'https://gitee.com/uploads/61/632261_smallweigit.jpg',
          unReadCount: 1,
          id: 1,
        },
        isread: false,
      };
    },
    computed: {
      currentChat: {
        get: function() {
          let currentChatObject= this.$store.state.chat.currentChat;
          let returnObject = {
            name: currentChatObject.name,
            img: currentChatObject.avatar,
            id: currentChatObject.id,
            type: currentChatObject.type,
          }
          return returnObject;
        },
        set: function(currentChat) {
          this.$store.commit(
            "setCurrentChat",
            JSON.parse(JSON.stringify(currentChat))
          );
        }
      },
      chatList: {
        get: function() {
          for(let i = 0,item; item = this.$store.state.chat.chatList[i++];){
            if(item.unReadCount > 0){
              this.isread = true;
            }else{
              this.isread = false;
            }
          }
          return this.$store.state.chat.chatList;
        },
        set: function(chatList) {
          this.$store.commit("setChatList", chatList);
        }
      },
      messageList: {
        get: function() {
          return this.$store.state.chat.messageList;
        },
        set: function(messageList) {
          this.$store.commit("setMessageList", messageList);
        }
      },
      totalUnReadMessage: {
        get: function() {
          let unReadCount = 0;
          let chatList = this.$store.state.chat.chatList;
          for(let i = 0,item; item = chatList[i++];){
            unReadCount += item.unReadCount
          }
          return unReadCount;
        }
      },
    },
    watch:{
      '$store.state.chat.messageList':{
        handler(value,oldValue){
          this.$nextTick(() => {
            this.dialogueScrollElement();
          })
        },
        deep: true,
      }
    },
    created() {
      // RequestUtils.getInstance().webSocketOperation(this);
      // this.chatList.push(this.tempObject);
    },
    activated: function() {
        let self = this;
        // 当前聊天室
        // if (self.$route.query.chat) {
        //   self.$store.commit(
        //     "setCurrentChat",
        //     JSON.parse(JSON.stringify(self.$route.query.chat))
        //   );
        // }
        // 重新设置chatList
        self.$store.commit(
          "setChatList",
          ChatListUtils.getChatList(self.$store.state.user.id)
        );
        // 每次滚动到最底部
        self.$nextTick(() => {
          imageLoad("message-box");
        });
    },
    methods: {
      showChat(chat) {
        let self = this;
        self.$store.commit("resetUnRead");
        self.currentChat = chat;
        // 每次滚动到最底部
        self.$nextTick(() => {
          imageLoad("message-box");
        });
      },
      delChat(chat) {
        this.$store.commit("delChat", chat);
      },
      handleAdd(msg) {
        this.keylist.push(msg);
        this.$message.success('快捷回复语添加成功')
      },
      handleSubmit(msg) {
        this.msg = msg || this.msg
        // this.$refs.chat.pushMsg({
        //   mine: true,
        //   text: this.msg
        // });
        // this.$refs.chat.rootSendMsg(this.getMsg(this.msg));
        this.msg = '';
        this.mineSend(msg);
      },
      // 本人发送信息
      mineSend(msg) {
        let self = this;
        let currentUser = self.$store.state.user.userInfo;
        let time = new Date().getTime();
        // console.log('currentUser',currentUser);
        // return;
        // let content = self.msg;
        if (msg !== "" && msg !== "\n") {
          if (msg.length > 2000) {
            self.$message({
              message:'发送的消息不能超过2000字',
              type: 'warning'
            })
          } else {
            let contentObjcet = new Object();
            contentObjcet.type = 'text';
            contentObjcet.text = msg;
            let currentMessage = {
              mine: true,
              fromAvatar: currentUser.avatar,
              fromName: currentUser.username,
              timestamp: time,
              fromId: currentUser.userId,
              content: JSON.stringify(contentObjcet),
              toId: self.currentChat.id,
              type: self.currentChat.type
            };
            // console.log(currentMessage);
            // return;
            self.send(currentMessage);
          }
        }
      },
      // 发送消息的基础方法
      send(message) {
        let self = this;
        // message.timestamp = self.formatDateTime(new Date(message.timestamp));
        message.isMine = true;
        self.$store.commit("sendMessage", message);
        self.$store.commit("addMessage", message);
        self.messageContent = "";
        // 每次滚动到最底部
        self.$nextTick(() => {
          // imageLoad("message-box");
        });
      },
      // 客服按钮点击
      showDialogue(){
        if(this.chatList.length > 0){
          this.showChat(this.chatList[0]);
          this.dialogShow = true;
          this.$nextTick(() => {
            this.dialogueScrollElement();
          })
        }else{
          this.$message({
            message: '暂时还没有顾客找您',
            type: 'warning'
          })
        }
      },
      beforeCloseChat(done){
          this.currentChat = {};
          done();
      },
      dialogueScrollElement(){
        // let scrollTargetElement = this.getClass("web__main")[0];
        let scrollTargetElement = document.getElementById('message-list');
        scrollTargetElement.scrollTop = scrollTargetElement.scrollHeight;
        // t.$refs.main.scrollTop=t.$refs.main.scrollHeight
        // console.log(scrollTargetElement);
      },
      getClass(param){
        //判断是否兼容
        if(document.getElementsByClassName){
            return document.getElementsByClassName(param);
        }else{
            //获取所有标签元素
            var all = document.getElementsByTagName('*');
            //创建一个空数组存放对象
            var arr = [];
            //筛选出对应的ClassName的元素
            for(var i = 0;i < all.length;i++){
              var allClass = all[i].className;
              var arrClass = allClass.split(' ');
              if(arrClass.indexOf(param) != -1){
                arr.push(all[i]);
              }
            }
            return arr;
        }
      },
      //滚动条到顶部获取新数据
      showScroll(event) {
        let st = event.target.scrollTop;
        // if (st == 0 && this.isGet == true) {
        //   this.messageParam.current += 1;
        //   this.getMessage();
        // }
      },
      //点击订单聊天框跳转到订单详情
      checkOrder(orderObject){
        // console.log(orderObject);
        this.$router.push({path:'/oms/orderDetail',query:{id:orderObject.orderId}})
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "../../asset/css/dialogue.css";
  $width: 280px;
  $height: 560px;

  $color-write: #ffffff;
  $color-main: #2590c2;
  $color-light-main: #69cbe9;
  $color-gray: #dddddd;
  $color-light-gray: #eeeeee;
  $color-default: #666666;
  $box-shadow: #aaaaaa;
  $color-message-bg: #5fb878;
  $color-box-bg: #f8f8f8;


  $small-size: 12px;
  $default-size: 14px;
  $large-size: 16px;
  $x-large-size: 18px;
  .chat-dialog /deep/{
    .el-dialog__body{
      display: flex;
      .dialogue .el-dialog__header {
          display: none;
      }
      .dialogue .el-dialog{
          /* background: #3C3D5C; */
      }
      .dialogue .el-dialog--center .el-dialog__body{
          padding: 0px !important;
      }
      .user-list{
        width: 200px;
      }
      .chat-dialog-rightwrap{
        flex: 1;
      }
      .message-user{
          width: 100%;
          height: 60px;
          padding: 5px;
          /* display: flex; */
          display: -webkit-box;
          flex-wrap: nowrap;
          border-bottom:1px solid #F5F5F5;
          box-sizing: border-box;
          position: relative;
          overflow: hidden;
      }
      .muser-img{
          width:40px;
          height: 40px;
          border-radius: 40px;
          overflow: hidden;
          margin-top: 6px;
      }
      .muser-img img{
          width:100%;
          height:100%;
      }
      .muser-name{
          flex: 8;
          font-size: 14px;
          padding-left: 5px;
          overflow: hidden;
          display: flex;
          text-overflow:ellipsis;
          white-space: nowrap;
          box-sizing: border-box;
          .muser-info-wrap{
            flex: 1;
            .user-name{
                color: #333;
                margin-top: 8px;
                margin-bottom: 6px;
                font-size: 12px;
            }
            .muser-name p{
                color: #999;
                display: inline-block;
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow:ellipsis;
            }
            .muser-name > span{
                font-size: 12px;
                color: #999;
                text-align: left;
            }
            .user-content{
                text-align: left;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
            }
          }
          .muser-cancelbtn-wrap{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
          }
      }
      .gray{

      }
      .username-box{
          background: #E9E9E9;
      }
      .axis{
          float:right;
          font-size: 12px;
          font-weight: 400;
          color: #999;
      }
      .unread{
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          border-radius: 20px;
          background: #409eff;
          color: #fff;
          font-size: 12px;
          position: absolute;
          left: 0;
      }
      .avue-chat{
        flex: 1;
        .web__footer{
          height: 130px;
          .web__msg{
            display: flex;
            flex-direction: column;
            // height: 60px;
            height: 100%;
            .web__msg-input{
              flex: 1;
            }
            .el-button-group{
              height: 30px;
              .el-dropdown__icon{
                line-height: 12px;
              }
            }
          }
        }
      }
      .msg-order{
          display: flex;
          box-sizing: border-box;
          width: 350px;
          padding: 5px;
          border: 1px solid #f4f4f4;
          border-radius: 5px;
          cursor: pointer;
          .order-image{
            width: 75px;
            height: 75px;
            border-radius: 7px;
            margin-right: 10px;
            overflow: hidden;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .order-info{
            display: flex;
            flex: 1;
            justify-content: space-between;
            // height: 100%;
            height: 75px;
            .nameAndSku{
              display: flex;
              flex: 1;
              height: 100%;
              flex-direction: column;
              justify-content: space-between;
              .order-prodName{
                width: 100%;
                font-size: 12px;
                color: #333;
                overflow : hidden;
                text-overflow: ellipsis;
                text-overflow: -o-ellipsis-lastline;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                box-sizing: border-box;
              }
              .order-prodSku{
                width: 100%;
                font-size: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                color: #999;
              }
            }
            .priceAndNum{
              display: flex;
              height: 100%;
              flex-direction: column;
              justify-content:  flex-end;
              align-items: flex-end;
              .order-price{
                font-size: 12px;
              }
              .order-num{
                color: #cccccc;
                font-size: 9px;
              }
              .order-status{
                color: #09BB07;
                font-size: 10px;
              }
            }
          }
      }
    }
  }
</style>
