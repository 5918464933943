var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      on: {
        click: function ($event) {
          _vm.logsFlag ? "" : _vm.handleOpen()
        },
      },
    },
    [
      _c(
        "el-badge",
        { attrs: { value: _vm.logsFlag ? "" : _vm.logsLen, max: 99 } },
        [_c("i", { staticClass: "icon-bug" })]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.box,
            title: "日志",
            fullscreen: "",
            width: "100%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.box = $event
            },
          },
        },
        [_c("logs")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }