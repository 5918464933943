export const eeFormInit = {
  name: 'eeFormInit',
  props: {},
  data() {
    return {
      componentLists: [//所有可用的组件
        {
          type: 'input',//通过鼠标或键盘输入字符
          routerPath: () => import('../packages/input/index.vue'),//路由地址
        },
      ],
      specialComponent: () => import('../packages/special/index/index.vue'),//路由地址,
    };
  },
};