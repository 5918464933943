import Layout from '@/page/index/'

export default [{
  path: '/info',
  component: Layout,
  redirect: '/info/index',
  children: [{
    path: 'index',
    name: '个人信息',
    component: () =>
      import(/* webpackChunkName: "page" */ '@/views/admin/user/info')
  }]
},{
  path: '/admin',
  component: Layout,
  name: 'admin',
  meta: {title: '权限管理',icon: 'icon-quanxianguanli'},
  children: [
    {
      path:'role/roleDetail',
      name: '角色详情',
      component: () => import('@/views/admin/newRole/roleDetail'),
      meta: {title: '角色详情', icon: 'icon-quanxianguanli'},
      hidden: true,
    }
  ]
},{
    path: '/pms',
    component: Layout,
    redirect: '/pms/product',
    name: 'pms',
    meta: {title: '产品管理', icon: 'product'},
    children: [
      {
        path: 'addProduct',
        name: '新增产品',
        component: () => import('@/views/pms/product/add'),
        meta: {title: '新增产品', icon: 'product-add'},
        hidden: true
      },
      {
        path: 'updateProduct',
        name: '修改产品',
        component: () => import('@/views/pms/product/update'),
        meta: {title: '修改产品', icon: 'product-add'},
        hidden: true
      },
      {
        path: 'oneProductComment',
        // name: '商品评价',
        name: '产品评价',
        component: () => import('@/views/pms/comment/productComment'),
        // meta: {title: '商品评价'},
        meta: {title: '产品评价'},
        hidden: true
      }
    ]
  },
  {
    path: '/mms',
    component: Layout,
    redirect: '/mms/product',
    name: 'mms',
    meta: {title: '商品管理', icon: 'product'},
    children: [
      {
        path: 'addProduct',
        name: '新增产品',
        component: () => import('@/views/pms/product/add'),
        meta: {title: '新增产品', icon: 'product-add'},
        hidden: true
      },
      {
        path: 'detail',
        name: '商品详情',
        component: () => import('@/views/mms/product/detail'),
        meta: {title: '商品详情', icon: 'product-add'},
        hidden: false
      },
      {
        path: 'updateProduct',
        name: '修改产品',
        component: () => import('@/views/pms/product/update'),
        meta: {title: '修改产品', icon: 'product-add'},
        hidden: true
      },
      {
        path: 'oneProductComment',
        // name: '商品评价',
        name: '产品评价',
        component: () => import('@/views/pms/comment/productComment'),
        // meta: {title: '商品评价'},
        meta: {title: '产品评价'},
        hidden: true
      }
    ]
  },
  {
    path: '/oms',
    component: Layout,
    redirect: '/oms/order',
    name: 'oms',
    meta: {title: '订单管理', icon: 'order'},
    children: [
      {
        path: 'orderDetail',
        name: '订单详情',
        component: () => import('@/views/oms/order/orderDetail'),
        meta: {title: '订单详情'},
        hidden:true
      },
      {
        path: 'orderTrail',
        name: '订单轨迹',
        component: () => import('@/views/oms/order/orderTrail'),
        meta: {title: '订单轨迹'},
        hidden:true
      },
      {
        path: 'afterSaleDetailProd',
        name: '退货详情',
        component: () => import('@/views/oms/aftersale/afterSaleDetailProd'),
        meta: {title: '退货详情'},
        hidden:true
      },
      {
        path: 'afterSaleDetailMoney',
        name: '退款详情',
        component: () => import('@/views/oms/aftersale/afterSaleDetailMoney'),
        meta: {title: '退款详情'},
        hidden:true
      },
      {
        path: 'deliverOrderList',
        name: '发货列表',
        component: () => import('@/views/oms/order/deliverOrderList'),
        meta: {title: '发货列表'},
        hidden:true
      }
    ]
  },
  {
    path:'/sms',
    component: Layout,
    redirect: '/sms/coupon',
    name: 'sms',
    meta: {title: '营销管理', icon: 'sms'},
    children: [
      {
        path: 'addCoupon',
        name: '添加优惠券',
        component: () => import('@/views/sms/coupon/add'),
        meta: {title: '添加优惠券'},
        hidden:true
      },
      {
        path: 'updateCoupon',
        name: '修改优惠券',
        component: () => import('@/views/sms/coupon/update'),
        meta: {title: '修改优惠券'},
        hidden:true
      },
      {
        path: 'addChCoupon',
        name: '添加平台优惠券模板',
        component: () => import('@/views/sms/chCoupon/add'),
        meta: {title: '添加平台优惠券模板'},
        hidden:true
      },
      {
        path: 'updateChCoupon',
        name: '查看平台优惠券',
        component: () => import('@/views/sms/chCoupon/update'),
        meta: {title: '查看平台优惠券'},
        hidden:true
      },
      {
        path: 'chCouponData',
        name: '平台优惠券数据',
        component: () => import('@/views/sms/chCoupon/components/chCouponData/index'),
        meta: {title: '平台优惠券数据'},
        hidden:true
      },
      {
        path: 'couponHistory',
        name: '优惠券领取详情',
        component: () => import('@/views/sms/coupon/history'),
        meta: {title: '优惠券领取详情'},
        hidden:true
      },
      {
        path: 'groupActivityDetail',
        name: '团购详情',
        component: () => import('@/views/sms/groupActivity/groupList/detail'),
        meta: { title: '团购详情' },
        hidden:true
      },
      {
        path: 'oneYuanDetail',
        name: '代付详情',
        component: () => import('@/views/sms/oneYuan/oneYuanDetail'),
        meta: { title: '代付详情' },
        hidden: true,
      },
      {
        path: 'discountsProdDetail',
        name: '约惠商品详情',
        component: () => import('@/views/sms/discounts/product/prodDetail'),
        meta: { title: '约惠商品详情' },
        hidden:true        
      },    
      {
        path: 'redPackageDetailList',
        name: '红包明细',
        component: () => import('@/views/sms/redPackets/redPackageDetailList'),
        meta: { title: '红包明细' },
        hidden:true        
      },
      {
        path: 'usersCouponDetailList',
        name: '优惠券明细',
        component: () => import('@/views/sms/usersCoupon/usersCouponDetailList'),
        meta: { title: '优惠券明细' },
        hidden:true        
      },
      {
        path: 'marketingActivity/detail/:id/:isOnSale',
        name: '活动-商品',
        component: () => import('@/views/sms/marketingActivity/detail/index.vue'),
        meta: { title: '活动-商品', keepAlive: true },
        hidden:true        
      },
      {
        path: 'marketingActivity/setContent/:id',
        name: '活动-配置内容',
        component: () => import('@/views/sms/marketingActivity/setContent/index.vue'),
        meta: { title: '活动-配置内容' },
        hidden:true        
      },
      {
        path: 'convertZone/setting/:id/:platformCode/:isOnSale',
        name: '兑换-设置',
        component: () => import('@/views/sms/convertZone/setting/index.vue'),
        meta: { title: '兑换-设置' },
        hidden:true        
      },
    ]    
  }
]
