import { serialize } from '@/util/util'
import { getStore } from '../util/store'
import NProgress from 'nprogress' // progress bar
import errorCode from '@/const/errorCode'
import router from '@/router/router'
import { Message } from 'element-ui'
import 'nprogress/nprogress.css'
import qs from 'qs'
import store from '@/store' // progress bar style
import { env } from '@/config/env'

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
axios.defaults.timeout = 30000
// 返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500 // 默认的
}
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true
// NProgress Configuration
NProgress.configure({
  showSpinner: false
})

// HTTPrequest拦截
axios.interceptors.request.use(config => {
  if(config.url !== '/pms/antifakecode/process'){
    NProgress.start() // start progress bar
  }
  // const tenantObject = getStore({ name: 'tenantId' });
  const TENANT_ID =  getStore({ name: 'tenantId' });
  const isToken = (config.headers || {}).isToken === false
  // const token = store.getters.access_token
  const token = getStore({
    name: 'access_token'
  }) || ''

  if (token && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + token// token
  }
  if (TENANT_ID) {
    config.headers['TENANT-ID'] = TENANT_ID // 租户ID
  }else{
    config.headers['TENANT-ID'] = '' // 租户ID
  }

  // headers中配置serialize为true开启序列化
  if (config.method === 'post' && config.headers.serialize) {
    config.data = serialize(config.data)
    delete config.data.serialize
  }

  if (config.method === 'get') {
    config.paramsSerializer = function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }

  return config
}, error => {
  return Promise.reject(error)
})

let statusFlag = 0;
// HTTPresponse拦截
axios.interceptors.response.use(res => {
  NProgress.done()
  const status = Number(res.status) || 200
  const message = res.data.msg || errorCode[status] || errorCode['default']
  if (status === 401) {
    if(statusFlag === 0){
      Message({
        message: message,
        type: 'error'
      })
      store.dispatch('FedLogOut').then(() => {
        router.push({ path: '/login' })
      })
      statusFlag = 401;
    }
    return
  }

  if (statusFlag === 401) statusFlag = 0;

  if (status !== 200 || res.data.code === 1) {
    Message({
      message: message,
      type: 'error'
    })
    // return Promise.reject(new Error(message))
    return Promise.reject(res.data.msg);
  }

  return res
}, error => {
  NProgress.done()
  return Promise.reject(new Error(error))
})

export default axios
