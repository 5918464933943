// import createPersistedState from "vuex-persistedstate";
import {
    Chat,
    ChatListUtils,
    MessageInfoType,
    MessageTargetType,
    transform,
    handleMessageContent,
    mapChangeObj,
  } from "@/util/chatUtils";
import conf from "@/chatConfig/index";
const chat = {
    state: {
        token: {},
        // 当前的用户
        user: {},
        websocket: {},
        //内存中的聊天记录
        messageListMap: new Map(),
        //聊天群的映射 id->chat
        chatMap:  new Map(),
        messageList: [],
        // 当前聊天窗口
        currentChat: {},
        // 所有的聊天窗口
        chatList: [],
        //好友列表
        userFriendList: [],
        //刷新token 定时器
        flushTokenTimerId: null,
        //群组列表
        chatGroupList: []        
    }, 
    mutations: {
        setFlushTokenTimerId: function(state, flushTokenTimerId) {
          state.flushTokenTimerId = flushTokenTimerId;
        },
        clearFlushTokenTimerId: function(state) {
          clearTimeout(state.flushTokenTimerId);
        },
        setUser: function(state, user) {
          state.user = user;
        },
        setUserFriendList: function(state, userFriendList) {
          state.userFriendList = userFriendList;
        },
        setChatGroupList: function(state, chatGroupList) {
          state.chatGroupList = chatGroupList;
        },
        setChatMap: function(state, chatMap) {
          state.chatMap = chatMap;
        },
        getMessageListMapFromStorage: function(state,id){
          state.messageListMap = ChatListUtils.getMessageListMap(id);
        },
        getChatListFromStorage: function(state,id){
          let chatListInStorage = ChatListUtils.getChatList(id);
          if(chatListInStorage && chatListInStorage.length > 0){
            state.chatList = chatListInStorage;
            state.chatList[0]["unReadCount"] = 0;
            // ChatListUtils.setChatList(state.user.userId, state.chatList);
            // state.currentChat = state.chatList[0];
            // state.messageList = state.messageListMap[state.chatList[0].id] || [];
          }else{
            state.chatList = [];
          }
        },
        setWebsocket: function(state, websocket) {
          state.websocket = websocket;
        },
        // 发送给服务器
        sendMessage: function(state, message) {
          let sendMessage = Object.assign({},message);
          delete sendMessage.isMine;
          let msg = {
            code: MessageInfoType.MSG_MESSAGE,
            message: sendMessage
          };
          state.websocket.send(JSON.stringify(msg));
        },
        resetUnRead: function(state) {
          if (typeof state.currentChat == "string") {
            state.currentChat = {};
          }
          state.currentChat["unReadCount"] = 0;
        },
        // 退出登录
        closeConnect: function(state) {
          state.websocket.heartReset();
          state.websocket.close();
        },
        // 退出后清除内存中的聊天信息
        clear: function(state) {
          state.messageList = [];
          state.messageListMap = new Map();
        },
        // 保存到内存
        addMessage: function(state, message) {
          let messageObject = handleMessageContent(state.chatList,message);
          state.messageList.push(messageObject);
          if(message.mine){
            state.messageListMap[message.toId] = ChatListUtils.cache50MessageLog(state.messageList);
          }else{
            state.messageListMap[message.fromId] = ChatListUtils.cache50MessageLog(state.messageList);
          }
          let messageListObject = mapChangeObj(state.messageListMap);
          ChatListUtils.setMessageListMap(state.user.userId, messageListObject);          
        },
        //收到消息时添加未读信息
        addUnreadMessage: function(state, message) {
          // message.content = transform(message.content);
          let messageObject = handleMessageContent(state.chatList,message);
          if (message.type === MessageTargetType.FRIEND) {
            // 从内存中取聊天信息
            let cacheMessages = state.messageListMap[message.fromId];
            if (cacheMessages) {
              cacheMessages.push(messageObject);
            } else {
              cacheMessages = [];
              cacheMessages.push(messageObject);
            }
            state.messageListMap[message.fromId] = ChatListUtils.cache50MessageLog(cacheMessages);
            let messageListObject = mapChangeObj(state.messageListMap);
            ChatListUtils.setMessageListMap(state.user.userId, messageListObject);            
          }
        },        
        // 在用户姓名下展示收到的最后一条信息
        setLastMessage: function(state, message) {
          let list = ChatListUtils.getChatList(state.user.userId);
          let tempChatList = list.map(function(chat) {
            if ( String(chat.id) === String(message.fromid) && message.type === "0") {
              chat.sign = message.content;
            } else if ( String(chat.id) === String(message.id) && message.type === "1") {
              chat.sign = message.content;
            }
            return chat;
          });
          // 放入缓存
          ChatListUtils.setChatList(state.user.userId, tempChatList);
          state.chatList = tempChatList;
        },
        setMessageList: function(state, messageList) {
          state.messageList = messageList;
        },
        setMessageListMap: function(state, messageListMap) {
          state.messageListMap = messageListMap;
        },
        setCurrentChat: function(state, currentChat) {
          if (typeof currentChat != "string") {
            state.currentChat = currentChat;
            state.currentChat["unReadCount"] = 0;
            let tempChatList = state.chatList.map(function(chat) {
              if (String(chat.id) === String(currentChat.id)) {
                chat["unReadCount"] = 0;
              }
              return chat;
            });
            if(currentChat.id && currentChat.id != ''){
              state.messageList = state.messageListMap[currentChat.id];
            }else{
              state.messageList = [];
            }
            // 放入缓存
            ChatListUtils.setChatList(state.user.userId, tempChatList);
          }
        },
        setChatList: function(state, chatList) {
          state.chatList = chatList;
        },
        delChat: function(state, chat) {
          state.chatList = ChatListUtils.delChat(state.user.id, chat);
        },
        /**
         * 设置未读消息条数
         * @param state state
         * @param message 消息
         */
        setUnReadCount: function(state, message) {
          let tempChatList = [];
          let tempChat = {};
          for (let chat of state.chatList) {
            // 给接受消息的聊天室未读数量 +1
            if ( String(chat.id) === String(message.fromId) && message.type === MessageTargetType.FRIEND) {
              if (!chat["unReadCount"]) {
                chat["unReadCount"] = 0;
              }
              chat["unReadCount"] = chat["unReadCount"] + 1;
              tempChat = chat;
            }else {
              tempChatList.push(chat);
            }
          }
          // 聊天列表没有此人的chat
          if (!tempChat.id && message.type === MessageTargetType.FRIEND) {
            tempChat = new Chat(
              message.fromId,
              message.fromName,
              message.fromAvatar,
              1,
              message.content,
              // state.user.mobile,
              // state.user.email,
              MessageTargetType.FRIEND,
              message.timestamp,
            );
          }
          // 添加到聊天室列表的第一个
          tempChatList.unshift(tempChat);
          // 重新设置chatList
          state.chatList = tempChatList;
          // 放入缓存
          ChatListUtils.setChatList(state.user.userId, tempChatList);
        },
    },  
    actions:{
      setUser(context,user){
        const { commit, dispatch, state, rootState} = context;
        commit('setUser',user);
        commit('getMessageListMapFromStorage',user.userId);
        commit('getChatListFromStorage',user.userId);
      }
    },
    // plugins: [createPersistedState()],   
}
export default chat