var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "avue-contail ftu-container",
      class: { "avue--collapse": _vm.isCollapse },
    },
    [
      _c("div", { staticClass: "avue-header" }, [_c("top")], 1),
      _c("div", { staticClass: "avue-layout" }, [
        _c("div", { staticClass: "avue-left" }, [_c("sidebar")], 1),
        _c(
          "div",
          { staticClass: "avue-main" },
          [
            _c("tags", { ref: "tagRef" }),
            _c(
              "el-scrollbar",
              { staticClass: "avue_main_scrollbar" },
              [
                _c(
                  "keep-alive",
                  [
                    _vm.$route.meta.$keepAlive
                      ? _c("RouterView", {
                          key: _vm.$route.fullPath,
                          staticClass: "avue-view",
                          on: { closeTargetTag: _vm.closeTargetTag },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                !_vm.$route.meta.$keepAlive
                  ? _c("RouterView", {
                      key: _vm.$route.fullPath,
                      staticClass: "avue-view",
                      on: { closeTargetTag: _vm.closeTargetTag },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "avue-shade", on: { click: _vm.showCollapse } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }