import {
  // getStore,
  setStore
} from '@/util/store'

const state = {
  luckyDrawEditData: {},
}
const getters = {
  luckyDrawEditData: state => state.luckyDrawEditData,
}
const actions = {}
const mutations = {
  setLuckyDrawEditDataFn (state, value = {}) {
    state.luckyDrawEditData = value
    setStore({ name: 'luckyDrawEditData', content: state.luckyDrawEditData, type: 'session' })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
