var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "avue-crud",
    {
      attrs: { data: _vm.logsList, option: _vm.option },
      scopedSlots: _vm._u([
        {
          key: "type",
          fn: function (scope) {
            return [
              _c("el-tag", { attrs: { type: "danger", size: "mini" } }, [
                _vm._v(_vm._s(scope.label)),
              ]),
            ]
          },
        },
        {
          key: "expand",
          fn: function (props) {
            return [
              _c("pre", { staticClass: "code" }, [
                _vm._v("      " + _vm._s(props.row.stack) + "\n    "),
              ]),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "template",
        { slot: "menuLeft" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini", icon: "el-icon-upload" },
              on: { click: _vm.send },
            },
            [_vm._v("上传服务器")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "mini", icon: "el-icon-delete" },
              on: { click: _vm.clear },
            },
            [_vm._v("清空本地日志")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }