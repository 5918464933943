var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.option
    ? _c(
        "div",
        { staticClass: "e-vue-form" },
        [
          _c(
            "el-form",
            {
              ref: "eVueForm",
              attrs: {
                model: _vm.obj,
                "label-width": _vm.option.labelWidth
                  ? String(_vm.option.labelWidth)
                  : "auto",
                disabled: _vm.option.disabled,
                "label-position": _vm.option.labelPosition,
                size: _vm.option.size || "small",
                "label-suffix": _vm.option.labelSuffix,
                "hide-required-asterisk": _vm.option.hideRequiredAsterisk,
                "inline-message": _vm.option.inlineMessage,
                "status-icon": _vm.option.statusIcon,
              },
            },
            [
              _c(
                "el-row",
                {
                  attrs: {
                    gutter: _vm.option.gutter,
                    type: _vm.option.rowType,
                    justify: _vm.option.justify || "start",
                    align: _vm.option.align || "top",
                  },
                },
                [
                  _vm._l(_vm.option.column, function (item, index) {
                    return _c(
                      "el-col",
                      {
                        key: index,
                        class: item.className,
                        style: item.style,
                        attrs: {
                          push: _vm.option.push || item.push,
                          pull: _vm.option.pull || item.pull,
                          tag: _vm.option.tag || item.tag,
                          offset: _vm.option.offset || item.offset,
                          xs: 24,
                          sm: 24,
                          lg: item.span
                            ? item.span >= 24
                              ? 24
                              : item.span
                            : 24,
                        },
                      },
                      [
                        item.title
                          ? _c("div", { staticClass: "evue-form-title" }, [
                              _vm._v(_vm._s(item.title)),
                            ])
                          : _vm._e(),
                        item.children && item.children.length
                          ? _c(
                              "div",
                              { staticClass: "e-vue-childen" },
                              _vm._l(
                                item.children,
                                function (childItem, childIndex) {
                                  return _c(
                                    "el-form-item",
                                    {
                                      key: childIndex,
                                      class: [
                                        { "e-vue-must": childItem.must },
                                        childItem["formItemClassName"],
                                      ],
                                      style: childItem.formItemStyle,
                                      attrs: {
                                        label: childItem.itemLabel || "",
                                        prop: childItem.itemProp
                                          ? childItem.itemProp
                                          : childItem.model,
                                        rules: childItem.itemRules,
                                        size: childItem.itemSize,
                                        "label-width":
                                          _vm.option.labelWidth &&
                                          childItem.itemLabel
                                            ? _vm.option.labelWidth
                                            : childItem.labelWidth &&
                                              childItem.itemLabel
                                            ? childItem.labelWidth
                                            : childItem.itemLabel
                                            ? "100px"
                                            : "auto",
                                      },
                                    },
                                    [
                                      _c(
                                        _vm.componentLists.find(
                                          (x) => x.type === childItem.type
                                        )
                                          ? _vm.componentLists.find(
                                              (x) => x.type === childItem.type
                                            ).routerPath
                                          : childItem.slotName
                                          ? _vm.specialComponent
                                          : "",
                                        {
                                          tag: "div",
                                          attrs: {
                                            option: childItem,
                                            currentIndex: index,
                                            isStartResetFields:
                                              _vm.isStartResetFields,
                                          },
                                          on: {
                                            "upload-filesAdded":
                                              _vm.uploadFilesAdded,
                                            "upload-beforeUpload":
                                              _vm.uploadBeforeUpload,
                                            "upload-uploadProgress":
                                              _vm.uploadUploadProgress,
                                            "upload-fileUploaded":
                                              _vm.uploadFileUploaded,
                                            "upload-error": _vm.uploadError,
                                            evueUploader: _vm.evueUploader,
                                          },
                                          model: {
                                            value: _vm.obj,
                                            callback: function ($$v) {
                                              _vm.obj = $$v
                                            },
                                            expression: "obj",
                                          },
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: childItem.slotName },
                                            [_vm._t(childItem.slotName)],
                                            2
                                          ),
                                          _vm._l(
                                            childItem.slotNameFormData,
                                            function (slotNameFormDataItem) {
                                              return _c(
                                                "template",
                                                {
                                                  slot: slotNameFormDataItem.slotName,
                                                },
                                                [
                                                  _vm._t(
                                                    slotNameFormDataItem.slotName
                                                  ),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                          _c(
                                            "template",
                                            { slot: childItem.prependSlot },
                                            [_vm._t(childItem.prependSlot)],
                                            2
                                          ),
                                          _c(
                                            "template",
                                            { slot: childItem.appendSlot },
                                            [_vm._t(childItem.appendSlot)],
                                            2
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                        _vm._t(item.slotName),
                      ],
                      2
                    )
                  }),
                  _c(
                    "el-col",
                    {
                      style: { "text-align": _vm.option.menuAlign },
                      attrs: { span: 24 },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "e-vue-form-menu" },
                        [
                          _vm.option.submitBtn
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.option.submitType,
                                    size:
                                      _vm.option.submitSize ||
                                      _vm.option.size ||
                                      "small",
                                  },
                                  on: { click: _vm.submit },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.option.submitTxt || "保存") +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.option.emptyBtn
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.option.emptyType,
                                    size:
                                      _vm.option.emptySize ||
                                      _vm.option.size ||
                                      "small",
                                  },
                                  on: { click: _vm.empty },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.option.emptyTxt || "清空") +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._t("menuBottom"),
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }