var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "basic-container",
      class: { "basic-container--block": _vm.block },
    },
    [_c("el-card", [_vm._t("default")], 2)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }