import {
  // getStore,
  setStore
} from '@/util/store'

const state = {
  inputBlurTime: 0, // 设置 vuex 用于在父组件 刷新校验规则
}
const getters = {
  inputBlurTime: state => state.inputBlurTime,
}
const actions = {}
const mutations = {
  setInputBlurTimeFn (state, value = {}) {
    state.inputBlurTime = value
    setStore({ name: 'inputBlurTime', content: state.inputBlurTime, type: 'session' })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
