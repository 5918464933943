<template>
  <el-tiptap class="elTiptap"
    :editorProperties="editorProperties"
    :placeholder="placeholder"
    :output="output"
    :readonly="readonly"
    :spellcheck="spellcheck"
    :width="width"
    :height="height"
    :showMenubar="showMenubar"
    :charCounterCount="charCounterCount"
    :tooltip="tooltip"
    :lang="'zh'"

    :extensions="extensionsRes"
    v-model="contentRes"
    @onInit="onInit"
    @onTransaction="onTransaction"
    @onFocus="onFocus"
    @onBlur="onBlur"
    @onPaste="onPaste"
    @onDrop="onDrop"
  >
    <template #menubar>
      <slot name="menubar"></slot>
    </template>
    <!-- 貌似废弃了 -->
    <template #menububble>
      <slot name="menububble"></slot>
    </template>
    <template #footer>
      <slot name="footer"></slot>
    </template>
  </el-tiptap>
</template>

<script>
import Vue from 'vue'
import {
  // 需要的 extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Link,
  Image,
  Iframe,
  CodeBlock,
  Blockquote,
  ListItem,
  BulletList,
  OrderedList,
  TodoItem,
  TodoList, // (与 TodoItem 一起使用)
  TextAlign,
  Indent,
  LineHeight,
  HorizontalRule,
  HardBreak,
  TrailingNode,
  History,
  Table, // (与 TableHeader, TableCell, TableRow 一起使用)
  TableHeader,
  TableCell,
  TableRow,
  FormatClear,
  TextColor,
  TextHighlight,
  Preview,
  Print,
  Fullscreen,
  SelectAll,
  FontType,
  FontSize,
  CodeView,
} from 'element-tiptap'; // 见 main.js
import codemirror from 'codemirror'; // install 'codemirror' in your own project
import 'codemirror/lib/codemirror.css'; // import base style
import 'codemirror/mode/xml/xml.js'; // language
import 'codemirror/addon/selection/active-line.js'; // require active-line.js
import 'codemirror/addon/edit/closetag.js'; // autoCloseTags
import { uploadMaterialImage } from '@/api/tools/material'

// 默认扩展
const extensionsDefault = [
  new Doc(),
  new Text(),
  new Paragraph(),
  new Heading({ level: 5 }),
  new Bold({ bubble: true }), // 在气泡菜单中渲染菜单按钮
  new Italic(),
  new Strike(),
  new Underline({ bubble: true, menubar: false }), // 在气泡菜单而不在菜单栏中渲染菜单按钮
  new Link(),
  new Image({
    urlPattern: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
    uploadRequest: function (file) {
      // console.log('uploadRequest file', file)
      const _limit10M = file.size / 1024 / 1024 < 50 // 限制10M
      return new Promise((resolve, reject) => {
        if (_limit10M) {
          const _formData = new FormData()
          _formData.append('file', file)
          _formData.append('fileTypeId', '0')
          uploadMaterialImage(_formData)
            .then(res => {
              if (res && res.code === 0 && res.data && res.data.url) {
                resolve(res.data.url)
              } else {
                console.log('图片上传失败 res', res)
                reject('')
              }
            })
            .catch(err => {
              console.log('图片上传出错 err', err)
              reject('')
            })
        } else {
          console.error('src/components/richText/richText.vue 图片最大不超过10M')
          Vue.prototype.$message.warning('图片最大不超过10M');
          reject('')
        }
      })
    }
  }),
  new Iframe(),
  new CodeBlock(),
  new Blockquote(),
  new ListItem(),
  new BulletList(),
  new OrderedList(),
  new TodoItem(),
  new TodoList(), // (与 TodoItem 一起使用)
  new TextAlign(),
  new Indent({ minIndent: 0, maxIndent: 10, }),
  new LineHeight(),
  new HorizontalRule(),
  new HardBreak(),
  new TrailingNode(),
  new History(),
  new Table({ resizable: true }), // (与 TableHeader, TableCell, TableRow 一起使用)
  new TableHeader(),
  new TableCell(),
  new TableRow(),
  new FormatClear(),
  new TextColor(),
  new TextHighlight(),
  new Preview(),
  new Print(),
  new Fullscreen(),
  new SelectAll(),
  new FontType(),
  new FontSize(),
  new CodeView({
    // the CodeMirror library.
    codemirror,
    // specify the options for CodeMirror.
    codemirrorOptions: {
      tabMode: 'indent',
      styleActiveLine: true,
      autoCloseTags: true,
      lineWrapping: true,
      tabSize: 2,
      lineNumbers: true, // 是否在编辑器左侧显示行号。
      readOnly: false, // 这将禁止用户编辑编辑器内容。如果"nocursor"给出了特殊值（而不是简单的true），也不允许编辑器聚焦。
      lineWiseCopyCut: true, // 在没有选择的情况下进行复制或剪切将复制或剪切带有光标的整行。
      pasteLinesPerSelection: true, // 当从外部源（而不是编辑器本身）粘贴某些内容时，如果行数与选择数匹配，CodeMirror 将默认为每个选择插入一行。您可以将其设置false为禁用该行为。
      cursorScrollMargin: 100, // 当接近可滚动文档中可见视图的顶部或底部时，始终在光标上方和下方保留多少额外空间。默认值为 0。
    },
  }),
]

// const editorProperties = {
//   editorProps: {
//       attributes: {
//         class: 'my-custom-class',
//       },
//     },
// }

export default {
  name: 'richText',
  props: {
    customize: {
      type: Boolean,
      default: false,
      validator: function (value) {
        return [true, false].indexOf(value) !== -1
      }
    },

    editorProperties: { type: Object, default: () => ({}) },
    placeholder: { type: String, default: '请输入' },
    output: { type: String, default: 'html' },
    readonly: { type: Boolean, default: false,
      validator: function (value) {
        return [true, false].indexOf(value) !== -1
      }
    },
    // 编辑器内容是否开启拼写检查。
    spellcheck: { type: Boolean, default: false,
      validator: function (value) {
        return [true, false].indexOf(value) !== -1
      }
    },
    width: { type: [String, Number], default: '' },
    height: { type: [String, Number], default: '' },
    // 是否显示 menubar
    showMenubar: { type: Boolean, default: true,
      validator: function (value) {
        return [true, false].indexOf(value) !== -1
      }
    },
    // 是否显示字数统计
    charCounterCount: { type: Boolean, default: true,
      validator: function (value) {
        return [true, false].indexOf(value) !== -1
      }
    },
    // 鼠标移到按钮上时是否显示 tooltip
    tooltip: { type: Boolean, default: true,
      validator: function (value) {
        return [true, false].indexOf(value) !== -1
      }
    },
    // 插件 lang 选项的值, 指定编辑器国际化语言。 也可在全局注册时 传入配置项
    lang: { type: String, default: 'en',
      validator: function (value) {
        return ['en', 'zh', 'pl', 'ru', 'de', 'ko', 'es', 'zh_tw', 'fr'].indexOf(value) !== -1
      }
    },

    extensions: { type: Array, default: () => ([]) },
    content: { type: String, default: '' }
  },
  computed: {
    extensionsRes () {
      if (this.customize) {
        return extensions
      } else {
        return extensionsDefault
      }
    },
    contentRes: {
      get () {
        return this.content
      },
      set (newValue) { 
        this.$forceUpdate()
        this.$emit('update:content', newValue)
        return newValue
      },
    },
  },
  watch: {
    contentRes: function (val) {
      this.$forceUpdate()
      this.$emit('update:content', val)
    }
  },
  mounted () {
  },
  data () {
    return {
    }
  },
  methods: {
    onInit ({ editor }) { this.$emit('onInit', editor) },
    onTransaction ({ editor }) { this.$emit('onTransaction', editor) },
    onFocus ({ editor }) { this.$emit('onFocus', editor) },
    onBlur ({ editor }) { this.$emit('onBlur', editor) },
    onPaste ({ editor }) { this.$emit('onPaste', editor) },
    onDrop ({ editor }) { this.$emit('onDrop', editor) },
  },
}
</script>

<style scoped lang="scss">
.elTiptap /deep/ .el-tiptap-editor__content {
  border-bottom: 1px solid #ebeef5;
}
</style>
