var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-tiptap", {
    staticClass: "elTiptap",
    attrs: {
      editorProperties: _vm.editorProperties,
      placeholder: _vm.placeholder,
      output: _vm.output,
      readonly: _vm.readonly,
      spellcheck: _vm.spellcheck,
      width: _vm.width,
      height: _vm.height,
      showMenubar: _vm.showMenubar,
      charCounterCount: _vm.charCounterCount,
      tooltip: _vm.tooltip,
      lang: "zh",
      extensions: _vm.extensionsRes,
    },
    on: {
      onInit: _vm.onInit,
      onTransaction: _vm.onTransaction,
      onFocus: _vm.onFocus,
      onBlur: _vm.onBlur,
      onPaste: _vm.onPaste,
      onDrop: _vm.onDrop,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "menubar",
          fn: function () {
            return [_vm._t("menubar")]
          },
          proxy: true,
        },
        {
          key: "menububble",
          fn: function () {
            return [_vm._t("menububble")]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [_vm._t("footer")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.contentRes,
      callback: function ($$v) {
        _vm.contentRes = $$v
      },
      expression: "contentRes",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }