var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "avue-logo" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.keyCollapse
          ? _c("span", { key: "0", staticClass: "avue-logo_subtitle" }, [
              _vm._v(" " + _vm._s(_vm.tenant.shortTitle) + " "),
            ])
          : _vm._e(),
      ]),
      _c(
        "transition-group",
        { attrs: { name: "fade" } },
        [
          !_vm.keyCollapse
            ? [
                _c(
                  "span",
                  {
                    key: "1",
                    staticClass: "avue-logo_title",
                    on: { click: _vm.goIndex },
                  },
                  [_vm._v(_vm._s(_vm.tenant.title) + " ")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }