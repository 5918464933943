var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showTag
    ? _c("div", { staticClass: "avue-tags" }, [
        _vm.contextmenuFlag
          ? _c(
              "div",
              {
                staticClass: "avue-tags__contentmenu",
                style: {
                  left: _vm.contentmenuX + "px",
                  top: _vm.contentmenuY + "px",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "item", on: { click: _vm.closeOthersTags } },
                  [_vm._v("关闭其他 ")]
                ),
                _c(
                  "div",
                  { staticClass: "item", on: { click: _vm.closeAllTags } },
                  [_vm._v("关闭全部 ")]
                ),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "avue-tags__box",
            class: { "avue-tags__box--close": !_vm.website.isFirstPage },
          },
          [
            _c(
              "el-tabs",
              {
                attrs: { closable: _vm.tagLen !== 1, type: "card" },
                on: { "tab-click": _vm.openTag, edit: _vm.menuTag },
                nativeOn: {
                  contextmenu: function ($event) {
                    return _vm.handleContextmenu.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.active,
                  callback: function ($$v) {
                    _vm.active = $$v
                  },
                  expression: "active",
                },
              },
              _vm._l(_vm.tagList, function (item, index) {
                return _c("el-tab-pane", {
                  key: index,
                  attrs: { label: item.label, name: item.value },
                })
              }),
              1
            ),
            _c(
              "el-dropdown",
              { staticClass: "avue-tags__menu", staticStyle: { top: "5px" } },
              [
                _c("el-button", { attrs: { type: "primary", size: "mini" } }, [
                  _vm._v(" 更多 "),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.closeOthersTags.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("关闭其他")]
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.closeAllTags.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("关闭全部")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }