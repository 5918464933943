var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "top-menu" },
    [
      _c(
        "el-menu",
        {
          attrs: {
            "default-active": _vm.activeIndex,
            mode: "horizontal",
            "text-color": "#333",
          },
        },
        [
          _vm._l(_vm.items, function (item, index) {
            return [
              _c(
                "el-menu-item",
                {
                  key: index,
                  attrs: { index: item.parentId + "" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.openMenu(item)
                    },
                  },
                },
                [
                  _c("template", { slot: "title" }, [
                    _c("i", { class: item.icon }),
                    _c("span", [_vm._v(_vm._s(item.label))]),
                  ]),
                ],
                2
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }