var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "setting" }, [
    _c("div", {
      staticClass: "setting__shade",
      class: { "setting__shade--show": _vm.isShade },
      on: { click: _vm.close },
    }),
    _c(
      "div",
      {
        staticClass: "setting__content",
        class: { "setting__content--show": _vm.box },
      },
      [
        _c("div", { staticClass: "setting__header" }, [_vm._v("版权信息")]),
        _vm._m(0),
        _vm._m(1),
        _c("el-scrollbar", { staticStyle: { height: "500px" } }, [
          _c(
            "div",
            { staticClass: "setting__body setting__form" },
            [
              _c("avue-form", {
                attrs: { option: _vm.option },
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "setting__body setting__about" }, [
      _c("p", [_vm._v("Version：eshop 2.0.0")]),
      _c("p", [_vm._v("Copyright: eshop ©2020-2025")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "setting__header" }, [
      _vm._v("设置 "),
      _c("small", [_vm._v("(滑动鼠标下面还有更多设置)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }