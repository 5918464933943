var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chat-wrap" },
    [
      _c(
        "el-badge",
        {
          attrs: {
            value: _vm.totalUnReadMessage > 0 ? _vm.totalUnReadMessage : "",
            max: 99,
          },
        },
        [
          _c("i", {
            staticClass: "el-icon-service",
            on: { click: _vm.showDialogue },
          }),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "chat-dialog",
          attrs: {
            visible: _vm.dialogShow,
            width: "50%",
            "close-on-press-escape": false,
            center: "",
            "before-close": _vm.beforeCloseChat,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogShow = $event
            },
          },
        },
        [
          _vm.chatList.length > 0
            ? _c(
                "div",
                { staticClass: "user-list" },
                _vm._l(_vm.chatList, function (chat, index) {
                  return _c(
                    "a",
                    {
                      key: index,
                      class:
                        _vm.currentChat && _vm.currentChat.id === chat.id
                          ? "active"
                          : "",
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function ($event) {
                          return _vm.showChat(chat)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "message-user" }, [
                        _c("div", { staticClass: "muser-img" }, [
                          chat.avatar && chat.avatar != ""
                            ? _c("img", {
                                attrs: { src: chat.avatar, alt: "头像" },
                              })
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "muser-name" }, [
                          _c("div", { staticClass: "muser-info-wrap" }, [
                            _c("div", { staticClass: "user-name gray" }, [
                              _vm._v(" " + _vm._s(chat.name) + " "),
                            ]),
                            _c("span", { staticClass: "user-content" }, [
                              _vm._v(" " + _vm._s(chat.sign) + " "),
                            ]),
                          ]),
                          _c("div", { staticClass: "muser-cancelbtn-wrap" }),
                        ]),
                        chat.unReadCount > 0
                          ? _c("div", { staticClass: "unread" }, [
                              _vm._v(_vm._s(chat.unReadCount)),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _c("div", { staticClass: "chat-dialog-rightwrap" }, [
            _c("div", { staticClass: "muser-info" }, [
              _vm.currentChat.img && _vm.currentChat.img != ""
                ? _c("div", { staticClass: "user-head-image" }, [
                    _c("img", { attrs: { src: _vm.currentChat.img } }),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "user-head-name" }, [
                _vm._v(" " + _vm._s(_vm.currentChat.name) + " "),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "message-list",
                attrs: { id: "message-list" },
                on: {
                  scroll: function ($event) {
                    return _vm.showScroll($event)
                  },
                },
              },
              _vm._l(_vm.messageList, function (item) {
                return _c("div", { key: item.id, staticClass: "list-info" }, [
                  _c("div", { staticClass: "send-time" }, [
                    _vm._v(" " + _vm._s(item.date) + " "),
                  ]),
                  !item.mine
                    ? _c("div", { staticClass: "chat-log" }, [
                        _c("div", { staticClass: "chat-userimg" }, [
                          _c("img", { attrs: { src: item.img } }),
                        ]),
                        _c("div", { staticClass: "chat-content" }, [
                          _c("div", { staticClass: "content-username" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                          _c("div", { staticClass: "left-content-text" }, [
                            item.text
                              ? _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(item.text.text),
                                  },
                                })
                              : _vm._e(),
                            item.product
                              ? _c("div", { staticClass: "msg-goods" }, [
                                  item.product.prodImage &&
                                  item.product.prodImage != ""
                                    ? _c("img", {
                                        attrs: { src: item.product.prodImage },
                                      })
                                    : _vm._e(),
                                  _c("p", [
                                    _vm._v(_vm._s(item.product.prodName)),
                                  ]),
                                  _c("span", { staticClass: "msg-price" }, [
                                    _vm._v(
                                      " ￥" +
                                        _vm._s(item.product.prodPrice) +
                                        " "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            item.order
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "msg-order",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.checkOrder(item.order)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "order-image" }, [
                                      item.order.prodImage &&
                                      item.order.prodImage != ""
                                        ? _c("img", {
                                            attrs: {
                                              src: item.order.prodImage,
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                    _c("div", { staticClass: "order-info" }, [
                                      _c("div", { staticClass: "nameAndSku" }, [
                                        _c(
                                          "div",
                                          { staticClass: "order-prodName" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.order.prodName) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "order-prodSku" },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.order.skuStr)),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "priceAndNum" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "order-price" },
                                            [
                                              _vm._v(
                                                " ￥" +
                                                  _vm._s(item.order.prodPrice) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "order-num" },
                                            [
                                              _vm._v(
                                                " x" +
                                                  _vm._s(item.order.prodNum) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "order-status" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("orderStatusFilter")(
                                                      item.order.orderStatus
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            item.product &&
                            item.product.prodImage &&
                            !item.product.productId
                              ? _c("img", {
                                  staticStyle: { width: "200px" },
                                  attrs: { src: item.product.prodImage },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                      ])
                    : _c("div", { staticClass: "chat-log-right" }, [
                        _c("div", { staticClass: "chat-content" }, [
                          _c("div", { staticClass: "content-username" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                          _c("div", { staticClass: "left-content-text" }, [
                            item.text
                              ? _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(item.text.text),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _c("div", { staticClass: "chat-userimg" }, [
                          _c("img", { attrs: { src: item.img } }),
                        ]),
                      ]),
                ])
              }),
              0
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }