<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {}
  },
  computed: {},
  watch: {
    // '$route': {
    //   handler: function (to, from) {
    //     console.log(to, 'to')
    //     console.log(from, 'from')
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    '$route' (to, from) {
      sessionStorage.setItem('routeTo', JSON.stringify(to.path))
      sessionStorage.setItem('routeFrom', JSON.stringify(from.path))
    }
  },
  created() {
  },
  methods: {}
}
</script>
<style lang="scss">
  #app {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
</style>
