<template>
  <el-table style="width: 100%"
    element="el-table"
    ref="elMultipleTable"
    :border="isShowBorder"
    :empty-text="emptyText"
    :row-key="rowKey"
    :tree-props="treeProps"
    :data="tableData"
    :default-sort="defaultSort"
    :span-method="spanMethod"
    :highlight-current-row="highlightCurrentRow"
    @select="handleSelect"
    @select-all="handleSelectAll"
    :max-height="maxHeight"
    @selection-change="handleSelectionChange"
    @sort-change="handleSortChange"
  >
    <!-- 是否 多选 -->
    <el-table-column
      type="selection"
      show-overflow-tooltip
      v-if="isShowMultipleSelection"
      :align="align"
      :fixed="true"
    >
    </el-table-column>
    <!-- :height="height"
    :max-height="maxHeight" -->
    <!-- 是否 显示 index -->
    <el-table-column
      label="序号"
      type="index"
      width="50"
      v-if="isShowIndexes"
      :align="align"
      :fixed="true"
    >
 
    </el-table-column>
    <!-- 渲染表头 -->
    <template v-for="(colConfig, index) in tHeaderData">
      <slot
        v-if="colConfig.slot"
        :name="colConfig.slot"
      >
      </slot>
      <component
        v-else-if="colConfig.component"
        :is="colConfig.component"
        :colConfig="colConfig"
        :key="index"
        :rowData="tableData[index] || {}"
      >
      </component>
      <el-table-column
        v-else
        v-bind="colConfig"
        :key="index"
        :width="colConfig.columnWidth || '-'"
        :min-width="colConfig.columnMinWidth || '-'"
        :fixed="colConfig.fixed"
        :align="align"
        :sortable="colConfig.sortable"
        show-overflow-tooltip
      >
      </el-table-column>
    </template>
  </el-table>
</template>

<script>
import Vue from 'vue'
import {
  Table,
  TableColumn
} from 'element-ui'

Vue.use(Table)
Vue.use(TableColumn)

export default {
  name: 'CommonTable',
  props: {
    // 是否显示表格的线
    isShowBorder: {
      type: Boolean,
      default: false
    },
    // 表格数据
    tableData: {
      type: Array,
      required: true,
      default: () => []
    },
    // 受否显示 索引
    isShowIndexes: {
      type: Boolean,
      default: false
    },
    // 索引的 label
    indexesLabel: {
      type: [String, Number],
      default: '序号'
    },
    // 是否显示 多选
    isShowMultipleSelection: {
      type: Boolean,
      default: false
    },
    // 表头
    tHeaderData: {
      type: Array,
      required: true,
      default: () => []
    },
    // 固定表头
    height: {
      // Table 的高度，默认为自动高度。
      // 如果 height 为 number 类型，单位 px；
      // 如果 height 为 string 类型，则这个高度会设置为 Table 的 style.height 的值，Table 的高度会受控于外部样式
      type: [String, Number],
      default: '-'
    },
    maxHeight: {
      // Table 的最大高度。合法的值为数字或者单位为 px 的高度。此时若表格所需的高度大于最大高度，则会显示一个滚动条
      type: [String, Number],
      default: '-'
    },
    // 固定  列
    fixed: {
      type: [String, Boolean],
      default: false
    },
    // 表格 td 内容 的对齐方式
    align: {
      type: [String],
      default: 'center'
    },
    // 无数据时 显示 的文字
    emptyText: {
      type: String,
      default: '暂无数据'
    },
    // 同 el-table 的 row-key
    rowKey: {
      type: [String, Function],
      default: ''
    },
    // 同 el-table 的 tree-props
    treeProps: {
      type: Object,
      default: () => {
        return { hasChildren: 'hasChildren', children: 'children' }
      }
    },
    // 排序
    defaultSort: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 合并行或列的计算方法
    spanMethod: {
      type: Function,
      default: ({ row, column, rowIndex, columnIndex }) => ('')
    },
    // 是否要高亮当前行
    highlightCurrentRow: {
      type: Boolean,
      default: false
    }
    //
  },
  mounted () {
    this.$nextTick()
      .then(() => {
        this.doLayout()
      })
  },
  methods: {
    // element-ui select 事件
    handleSelect (selection, row) {
      this.$emit('select', selection, row)
    },
    // element-ui select-all 事件
    handleSelectAll (selection) {
      this.$emit('select-all', selection)
    },
    // element-ui selection-change 事件
    handleSelectionChange (selection) {
      // if (!this.isShowMultipleSelection) { return }
      this.$emit('selection-change', selection)
    },
    // 清空多选
    clearSelection () {
      this.$refs.elMultipleTable.clearSelection()
    },
    // 对 Table 进行重新布局
    doLayout () {
      this.$refs.elMultipleTable.doLayout()
    },
    // 设置多选
    toggleRowSelection (item) {
      this.$refs.elMultipleTable.toggleRowSelection(item)
    },
    handleSortChange ({ column, prop, order }) {
      this.$emit('sort-change', { column, prop, order })
    }
    //
  }
  //
}
</script>
