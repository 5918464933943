import request from '@/router/axios'

export function getPage(query) {
  return request({
    // url: 'api/material/page',
    url: 'tool/sys-file/page',
    method: 'get',
    params: Object.assign(query,{shopId:0})
  }).then((res) => { return res.data })
}

export function addObj(obj) {
  return request({
    url: 'api/material',
    method: 'post',
    data: Object.assign(obj,{shopId:0})
  })
}

export function getObj(id) {
  return request({
    url: 'api/material/' + id,
    method: 'get'
  })
}

export function delObj(id) {
  return request({
    // url: 'api/material/' + id,
    url: '/tool/sys-file/delete',
    method: 'post',
    data: { ids: [id] }
  })
}

export function putObj(obj) {
  return request({
    url: 'api/material',
    method: 'put',
    data: Object.assign(obj,{shopId:0})
  })
}

export function uploadMaterialImage(obj){
  let upLoadconfig = {
    headers: {
    'Content-Type': 'multipart/form-data'
    }
  }        
  return request({
    method: 'post',
    url: 'tool/sys-file/upload',
    data: obj,
    upLoadconfig,
  }).then((res) => {
      return res.data;
  })
}