var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-wrapper" },
    [
      _vm._l(_vm.menu, function (item) {
        return [
          _vm.validatenull(item[_vm.childrenKey]) &&
          _vm.vaildRoles(item) &&
          item.type != 2
            ? _c(
                "el-menu-item",
                {
                  key: item[_vm.labelKey],
                  class: { "is-active": _vm.vaildAvtive(item) },
                  attrs: { index: item[_vm.pathKey] },
                  on: {
                    click: function ($event) {
                      return _vm.open(item)
                    },
                  },
                },
                [
                  _c("i", { class: item[_vm.iconKey] }),
                  _c(
                    "span",
                    {
                      attrs: { slot: "title", alt: item[_vm.pathKey] },
                      slot: "title",
                    },
                    [_vm._v(_vm._s(item[_vm.labelKey]))]
                  ),
                ]
              )
            : !_vm.validatenull(item[_vm.childrenKey]) &&
              _vm.vaildRoles(item) &&
              item.type != 2
            ? _c(
                "el-submenu",
                {
                  key: item[_vm.labelKey],
                  attrs: { index: item[_vm.pathKey] },
                },
                [
                  _c("template", { slot: "title" }, [
                    _c("i", { class: item[_vm.iconKey] }),
                    _c(
                      "span",
                      {
                        class: {
                          "el-menu--display": _vm.collapse && _vm.first,
                        },
                        attrs: { slot: "title" },
                        slot: "title",
                      },
                      [_vm._v(_vm._s(item[_vm.labelKey]))]
                    ),
                  ]),
                  _vm._l(item[_vm.childrenKey], function (child, cindex) {
                    return [
                      _vm.validatenull(child[_vm.childrenKey]) &&
                      child.type != 2
                        ? _c(
                            "el-menu-item",
                            {
                              key: child[_vm.labelKey],
                              class: { "is-active": _vm.vaildAvtive(child) },
                              attrs: { index: child[_vm.pathKey], cindex },
                              on: {
                                click: function ($event) {
                                  return _vm.open(child)
                                },
                              },
                            },
                            [
                              _c("i", { class: child[_vm.iconKey] }),
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [
                                  _vm._v(
                                    " " + _vm._s(child[_vm.labelKey]) + " "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _c("sidebar-item", {
                            key: cindex,
                            attrs: {
                              menu: [child],
                              props: _vm.props,
                              screen: _vm.screen,
                              collapse: _vm.collapse,
                            },
                          }),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }